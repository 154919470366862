import storage, { GlobalAccountID, NonEncryptingSecret } from "./storage";

import Bugsnag from "@bugsnag/js";
import determineLogLevelsAccordingToStorage from "../../lib/RemoteLogging/determineLogLevelsAccordingToStorage";
import logRemotely from "../../lib/RemoteLogging/logRemotely";
import remoteLoggingEnabledVar from "../../lib/RemoteLogging/remoteLoggingEnabledVar";
import setTwilioVideoLogLevelBasedOnOurLoggerLevel from "../../lib/setTwilioVideoLogLevelBasedOnOurLoggerLevel";
import toEmoji from "./to-emoji";

export enum LogLevel {
  None = "None",
  Error = "Error",
  Warn = "Warn",
  Log = "Log",
  Debug = "Debug",
}

const allowedByLogLevel = new Map<LogLevel, (keyof Console)[]>([
  [
    LogLevel.Debug,
    [
      "debug",
      "error",
      "info",
      "log",
      "warn",
      "table",
      "trace",
      "group",
      "groupCollapsed",
      "groupEnd",
      "clear",
      "count",
      "countReset",
      "assert",
      "profile",
      "profileEnd",
      "time",
      "timeLog",
      "timeEnd",
      "timeStamp",
      "memory",
    ] as (keyof Console)[],
  ],
  [
    LogLevel.Log,
    [
      "error",
      "log",
      "warn",
      "table",
      "trace",
      "group",
      "groupCollapsed",
      "groupEnd",
      "clear",
      "count",
      "countReset",
      "assert",
      "profile",
      "profileEnd",
      "time",
      "timeLog",
      "timeEnd",
      "timeStamp",
      "memory",
    ] as (keyof Console)[],
  ],
  [
    LogLevel.Warn,
    [
      "error",
      "warn",
      "group",
      "groupCollapsed",
      "groupEnd",
      "clear",
    ] as (keyof Console)[],
  ],
  [
    LogLevel.Error,
    [
      "error",
      "group",
      "groupCollapsed",
      "groupEnd",
      "clear",
    ] as (keyof Console)[],
  ],
  [LogLevel.None, [] as (keyof Console)[]],
]);

type SpruceConsole = Omit<Console, "error"> & {
  setLogLevel: (logLevel: LogLevel) => void;
  resetLogLevel: () => void;
  logLevel: LogLevel;
  error: (error: Error, ...rest: any[]) => void;
};

function createConsole(): SpruceConsole {
  const { localLogLevel, remoteLogLevel } =
    determineLogLevelsAccordingToStorage();
  remoteLoggingEnabledVar(!!remoteLogLevel);
  const logLevel = remoteLogLevel ?? localLogLevel;

  const allowedMethods = allowedByLogLevel.get(logLevel);
  const console = {
    setLogLevel: (logLevel) => {
      console.logLevel = logLevel;
      storage.setItem({
        accountID: GlobalAccountID,
        secret: NonEncryptingSecret,
        key: "Console",
        store: "session",
        value: logLevel,
      });
      setTwilioVideoLogLevelBasedOnOurLoggerLevel();
    },
    resetLogLevel: () => {
      storage.removeItem({
        accountID: GlobalAccountID,
        secret: NonEncryptingSecret,
        key: "Console",
        store: "session",
      });
    },
    logLevel,
  } as SpruceConsole;

  Object.keys(window.console).forEach((m) => {
    const method = m as keyof Console;
    // @ts-ignore
    console[method] = (...args) => {
      if (!allowedMethods?.includes(method)) {
        if (typeof args[0] === "string") {
          Bugsnag.leaveBreadcrumb(...args);
        }
        return;
      }

      const firstArg = args[0];

      let prefix;
      if (typeof firstArg === "string") {
        const match = firstArg.match(/^(.*?):/);
        if (match) {
          prefix = toEmoji(match[1]);
        }
      }
      const origFn = console[method];
      console[method] = Function.prototype.bind.call(
        window.console[method],
        console
      );
      // @ts-ignore
      console[method].apply(console, prefix ? [prefix, ...args] : args);

      // @ts-ignore
      console[method] = origFn;

      try {
        const isFirstArgString = typeof firstArg === "string";
        const isFirstArgObjectWithMessageString =
          typeof firstArg === "object" &&
          "message" in firstArg &&
          typeof firstArg.message === "string";
        let message = isFirstArgString
          ? firstArg
          : isFirstArgObjectWithMessageString
            ? firstArg.message
            : "";

        // Right now we could be logging PHI to bugsnag. Let's sanitize this until we can sanitize up the stack.
        if (
          message &&
          message.startsWith(
            `Cannot resolve a DOM node from Slate node: {"text"`
          )
        ) {
          message = `Cannot resolve a DOM node from Slate node: {"text" : "TEXT REMOVED IN logger.ts"}`;
          if (isFirstArgObjectWithMessageString) {
            firstArg.message = message;
          }
        }
      } catch {
        // No-op: We'll just Bugsnag what was initially going to be Bugsnag'd.
      }
      // Developer action needed: convert this logging statement's arguments to type (firstArg: string, secondArg: Object)
      // the second arg should be metadata that Bugsnag displays nicely in a tabular format
      if (args[1] && !(args[1] instanceof Object)) {
        debugger;

        // This results in duplicate logs, but at least the argument gets printed
        Bugsnag.leaveBreadcrumb(firstArg, { unnamedArgument: args[1] });
      }

      if (method === "error") {
        Bugsnag.notify(firstArg);
      } else {
        Bugsnag.leaveBreadcrumb(...args);
      }

      logRemotely({ method, args });
    };
  });

  return console;
}

export default createConsole();
